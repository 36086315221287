// For production
// export const env = "https://hindici.com";

// For local
// export const env = "http://localhost:5000";

const isDevelopment = process.env.NODE_ENV === "development";

export const env = isDevelopment
  ? process.env.REACT_APP_API_URL || "http://localhost:5000"
  : "https://hindici.com";

export const basename = isDevelopment
  ? process.env.REACT_APP_BASENAME || ""
  : "/app";

// export const env = process.env.REACT_APP_API_URL || "http://localhost:5000";
// export const basename = process.env.REACT_APP_BASENAME || "";